.logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    padding: 34px 0px;
    overflow-x: scroll;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;

    @media (min-width: 1025px) {
        gap: 109px;
        justify-content: center;
    }

    &__icon_hepa {
        height: 35px;
        padding: 0px 15px;
    }

    &__icon_jetoil {
        height: 62px;
    }

    &__icon_markoil {
        height: 62px;
    }

    &__icon_neste {
        height: 25px;
    }

    &__icon_terminal {
        height: 62px;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.logos::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.logos {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}