.password-recovery-email-sent {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__picture {
		height: 300px;
		width: 300px;
		background: url("/../public/images/svg/mailbox.svg") no-repeat 50% 50%;
		background-size: contain;
		margin-bottom: 36px;
		margin-top: 10px;
	}
}

.recovery {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 36px;
	padding-bottom: 50px;

	&__title {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 36px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding-bottom: 3px;
		text-align: center;
	}

	&__question {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&__link {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-decoration-line: underline;
	}

	&__button {
		border-radius: 30px;
		background: #1A273D;
		color: #FFF;
		text-align: center;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&__button:hover {
		background: lighten(#1A273D, 10%);
	}
}