.oils {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	align-content: center;

	&__item {
		display: flex;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.1;
		flex-direction: row;
		flex-wrap: wrap;
		white-space: nowrap;
		margin-right: 7px;
		margin-bottom: 4px;
		border-radius: 25px;
		background: #eeeeee;
		padding: 4px 20px 4px 7px;
		align-content: center;
		justify-content: center;
	}

	&__item-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 11px;
		line-height: 1;
	}

	&__item-title {
		align-self: center;
		align-content: center;
		background: #fff;
		line-height: 40px;
		text-align: center;
		width: 40px;
		border-radius: 50%;
	}
}
