.section-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.section {
    max-width: 1440px;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    
    @media (min-width: 1025px) {
        padding: 0px 80px;
    }
}

