.layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;


	.loginContainer {
		display: flex;
		flex-direction: column;
		max-width: 347px;
		width: 100%;
		align-self: center;
	}
}