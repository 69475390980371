@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

html,
body,
#root {
	font-family: 'Bricolage Grotesque', 'DM Sans', sans-serif;
	padding-top: 0;
	margin-top: 0;
	height: 100%;
}

// .form-group input:hover, .form-group textarea:hover {
// 	border-color: hsl(0, 0%, 70%);
// 	transition: all 100ms;
// }

.form-group input:focus,
.form-group textarea:focus {
	border-color: #80bdff;
}

.input {
	&__container {
		position: relative;
	}

	&__icon {
		position: absolute;
		width: 27px;
		height: 27px;
		top: 50%;
		transform: translateY(-50%);
		right: 7px;
		cursor: pointer;
	}

	&__padding {
		padding-right: 40px;
	}
}

a {
	&:hover {
		text-decoration: none;
	}
}

.form-control.is-invalid {
	background-image: none;
}

.alert-link {
	font-weight: 500 !important;
}

strong {
	font-weight: 500 !important;
}

section {
	margin-bottom: 2rem;
}


body {
	background: #fff;
}

hr {
	margin: 0px
}

@import "./components/LandingPage/style.scss";
@import "./components/SignInPage/style.scss";
@import "./components/HomePage/style.scss";
@import "./components/AccountRecoveryPage/style.scss";
@import "./components/SignUpPage/style.scss";
@import "./components/InformationPage/style.scss";
@import "./components/CreateOilRequestPage/style.scss";
@import "./components/OilRequestsPage/style.scss";
@import "./components/ConversationPage/style.scss";
@import "./components/CustomerStoriesPage/style.scss";
@import "./components/SettingsPage/style.scss";
@import "./components/OilRequestPage/style.scss";
@import "./common/Layout/style.scss";
@import "./common/Footer/style.scss";
@import "./common/HeaderLight/style.scss";
@import "./common/PageTitle/style.scss";
@import "./common/PageSectionTitle/style.scss";
@import "./common/Oil/style.scss";
@import "./common/Section/style.scss";
@import "./common/OilsMainPage/style.scss";
@import "./common/Loader/style.scss";
@import "./common/Logos/style.scss";
@import "./common/Toasts/style.scss";
@import "./common/Protected/style.scss";
@import "./common/Date/style.scss";
@import "./common/Select/style.scss";
@import "./common/Oils/style.scss";