.date-input {

  &__calendar {
    position: absolute;
    padding: 8px;
    max-height: 36px;
    width: 36px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    pointer-events: fill;
  }

  svg path {
    transition: all 150ms;
  }

  svg {
    cursor: pointer;
    fill: #CCCCCC;

    &:hover {
      fill: #666666;
    }
  }

  &--open {
    svg {
      fill: #666666;
    }

    svg:hover {
      fill: #333333;
    }
  }

  .form-control.is-invalid {
    background-image: none;
  }

  .rdtPicker {
    top: 100%;
    background-color: hsl(0,0%,100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
  }
}
