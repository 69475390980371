.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 50px;

  &__title {
    color: #1A273D;
    font-family: "DM Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 3px;
  }

  &__question {
    color: #1A273D;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__link {
    color: #1A273D;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }
}

.login-page {
  display: flex;
  height: 100%;

  &__link {
    color: #1A273D;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }

  &__link:hover {
    color: lighten(#1A273D, 10%);
  }

  &__forgot-password {
    display: flex;
    justify-content: flex-end;
    color: #1A273D;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    margin-top: 20px;
  }

  &__option {
    color: #1A273D;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 0px;
  }

  &__login-button {
    border-radius: 30px;
    background: #1A273D;
    color: #FFF;
    text-align: center;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    margin-top: 20px;
  }

  &__login-button:hover {
    background: lighten(#1A273D, 10%);
  }

  &__alternative-login-methods {
    margin-bottom: 36px;
  }
}

.google-button,
.facebook-button {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  letter-spacing: .25px;
  border: none;
  overflow: hidden;
  text-align: center;
  text-overflow: clip;
  white-space: nowrap;
  border: 1px solid transparent;
  padding: 0;
  margin-bottom: 8px;
  
  i {
    font-size: 27px;
  }

  img {
    height: 27px;
    width: 27px;
  }

  &__icon {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
  }

  &:hover {
    cursor: pointer;
  }

  span {
    flex: 1;
  }
}

.facebook-button {
  background-color: #344f88;
  color: #fff;

  i {
    color: #344f88;
  }
}

.google-button {
  background: #4285f4;
  color: #fff;
}