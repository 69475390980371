.information-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&__title {
		color: #1A273D;
		font-family: "Bricolage Grotesque";
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		padding-bottom: 25px;
		padding-top: 11px;

		@media (min-width: 1025px) {
			padding-bottom: 25px;
			padding-top: 11px;
			font-size: 48px;
		}
	}

	&__coloredNumbers {
		ol {
			counter-reset: item;
			list-style: none;
			padding-left: 40px;
		}

		ol li {
			counter-increment: item;
			margin-bottom: 10px;
			position: relative;
			padding-left: 5px;
		}

		ol li::before {
			content: counter(item) ".";
			background-color: #DB9E21;
			border-radius: 50%;
			display: inline-block;
			width: 26px;
			height: 26px;
			line-height: 26px;
			color: black;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			padding-left: 1px;
			text-align: center;
			position: absolute;
			left: -30px;
			top: 0;
			transform: translateY(0);
		}
	}

	&__quick-answer {
		margin-bottom: 12px;
	}

	&__header {
		background: #a61d19;
	}

	&__question {
		font-weight: 500;
		margin-top: 18px;
		margin-bottom: 9px;
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&__terms {
		ol {
			counter-reset: item;
			list-style: none;
		}

		ol li {
			padding: 3px 0px;
			counter-increment: item;

			&:before {
				margin-right: 10px;
				content: counters(item, ".") " ";
				display: inline-block;
			}
		}
	}
}