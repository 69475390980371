.oilItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: fit-content;

    &__amount {
        color: #000;
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__description {
        color: #000;
        font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__ring {
        width: 37px;
        height: 37px;
        border: 2px solid #D1D4D8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #D1D4D8;
        transition: background-color 0.3s, border-color 0.3s;
    }

    &__ring span {
        color: #1A273D;
        font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

    }
}