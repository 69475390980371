.footer-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	background: #1A273D;
}

.footer {
	max-width: 1440px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 60px 30px;
	flex-direction: column;

	@media (min-width: 1025px) {
		padding: 70px 80px;
		flex-direction: row;
	}

	&__columns {
		color: white;
		display: flex;
		flex-direction: column;
		gap: 40px;
		justify-content: space-between;

		@media (min-width: 1025px) {
			flex-direction: row;
			gap: 80px;
		}
	}

	&__column {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__title {
		color: #FFF;
		font-family: "Bricolage Grotesque";
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	&__links-container {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	&__link {
		text-decoration: none;
		color: #ffffff;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&__link:hover {
		color: darken(#ffffff, 10%);
	}

	&__link-email {
		color: #ffffff;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&__link-email:hover {
		color: darken(#ffffff, 10%);
	}

	&__text {
		color: #ffffff;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding-top: 3px;
	}

	&__logo {
		margin-top: 40px;

		@media (min-width: 1025px) {
			display: flex;
			align-items: center;
			margin-top: 0px;
		}
	}
}