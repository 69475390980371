$screen-lg: 1440px;

.conversation-container {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 220px);

	@media (min-width: 1025px) {
		height: calc(100vh - 175px);
	}
}

.conversation {
	display: flex;
	flex-direction: row;
	height: calc(100% - 63px);
	overflow: hidden;

	@media (max-width: $screen-lg) {
		height: 100%;
	}

	&__send {
		color: #007bff;
		cursor: pointer;
		padding: 5px;

		&--disabled {
			color: #f8f9fa;
			cursor: auto;
		}
	}

	&__messages-container {
		padding-left: 20px;
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__messages-form {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		padding-top: 5px;

		&__textarea {
			flex: 1;
			margin-right: 15px;

			textarea {
				resize: none;
			}
		}
	}

	&__messages {
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		flex: 1;

		> div:first-child {
			margin-top: auto;
		}
	}

	&__left {
		overflow-y: auto;
		min-width: 100px;
		max-width: 275px;
		flex: 1;
    border-right: 1px solid #f2f2f2;

		&--mobile {
			min-width: 55px;
			max-width: 55px;
			flex: 0;

			.conversation__conversation {
				padding: 5px;
			}

			.conversation__conversation-title span {
				text-overflow: unset;
			}
		}
	}

	&__conversation {
		position: relative;
		padding: 15px 10px;
		border-radius: 3px;

		&--active {
			background: rgba(0, 0, 0, .05);
		}

		&--unread {
			font-weight: 500;
			padding-right: 30px;

			small {
				font-weight: 500;
			}

			.conversation__conversation-content {
				small {
					padding-right: 20px;
				}
			}
		}

		&:hover {
			cursor: pointer;
		}
	}

	&__conversation-dot {
		background: #007bff;
		height: 10px;
		width: 10px;
		border-radius: 10px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 7px;
	}

	&__conversation-title {
		display: flex;
		align-items: center;
		line-height: 1.2;

		span {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 5px;
		}

		small {
			white-space: nowrap;
			margin-left: auto;
		}
	}

	&__conversation-content {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.message {
	position: relative;
	margin-bottom: 15px;
	line-height: 1;
	margin-right: 15px;

	&__date {
		display: block;
		line-height: 1;
		color: #333;
	}

	&__content {
		display: inline-block;
		color: #333;
		line-height: 1.2;
		word-break: break-word;
		border-radius: 9px 9px 9px 0px;
		background: #f7f7f7;
    padding: 13px;
		max-width: 75%;
		margin-bottom: 5px;
	}

	&--left {
		.message__content {
			background: #eee;
		}
	}

	&--right {
		.message__content {
			float: right;
			background: #007bff;
			color: #fff;
			border-radius: 9px 9px 0px 9px;
		}

		.message__date {
			text-align: right;
			clear: both;
		}
	}
}
