.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 13px 0px;

	@media (min-width: 1025px) {
		padding: 13px 16px;
	}

	.logo {
		height: 60px;
	}

	.userDetails {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;

		@media (min-width: 1025px) {
			display: none;
		}
	}

	.userIcon {
		display: flex;
		width: 42px;
		height: 42px;
		padding: 19px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
		border-radius: 30.5px;
		background: #FFF;
	}

	.userIconText {
		color: #1A273D;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.48px;
		text-transform: uppercase;
		line-height: 1;
	}

	.userText {
		display: flex;
		flex-direction: column;
	}

	.userRole {
		color: #FFF;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.36px;
		text-transform: uppercase;
	}

	.userName {
		color: #FFF;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		max-width: 78px;
		max-width: 120px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre-wrap;
	}
}

.protected-page {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	padding-top: 174px;

	@media (min-width: 1025px) {
		height: 100%;
		padding-top: 89px;
	}

	&__container {
		display: flex;
		flex: 1;
		overflow-y: scroll;
	}

	&__layout {
		display: flex;
		flex-direction: column;

		@media (min-width: 1025px) {
			flex-direction: row;
		}
	}

	&__content {
		margin-top: 23px;
		margin-bottom: 23px;
		width: 100%;

		@media (min-width: 1025px) {
			margin-top: 43px;
			margin-bottom: 43px;
			margin-left: 43px;
			width: 100%;
		}
	}

	&__nav-desktop {
		flex-shrink: 0;
		height: calc(100vh - 89px);
		position: sticky;
		top: 0;
		overflow: visible;

		.nav-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: calc(100vh - 172px);
			width: 247px;
			flex-shrink: 0;
			border-radius: 10px;
			background: #1A273D;
			margin-top: 43px;
			margin-bottom: 43px;
			padding: 20px;


			.top-container {
				display: flex;
				flex-direction: column;
			}

			.user-details {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 20px;

				&__icon {
					display: flex;
					width: 50px;
					height: 50px;
					padding: 19px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 10px;
					flex-shrink: 0;
					border-radius: 30.5px;
					background: #FFF;
				}

				&__icon-text {
					color: #1A273D;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.48px;
					text-transform: uppercase;
					line-height: 1;
				}

				&__description {
					display: flex;
					flex-direction: column;
				}

				&__role {
					color: #FFF;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					letter-spacing: 0.36px;
					text-transform: uppercase;
				}

				&__name {
					color: #FFF;
					font-family: "DM Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					max-width: 78px;
					max-width: 120px;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: pre-wrap;
				}
			}

			.links {
				display: flex;
				flex-direction: column;
				padding-top: 30px;
				gap: 24px;

				.protected-link {
					display: flex;
					flex-direction: row;
					gap: 14px;
					color: #FFF;
					font-family: "DM Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-decoration: none;
					align-items: center;
					width: max-content;

					&-active {
						font-weight: 700;
					}
				}

				.protected-link:hover {
					color: darken(#ffffff, 15%);
				}

				.custom-badge {
					border-radius: 20px;
					background-color: #CF0000;
					padding-left: 6px;
					padding-right: 6px;
					font-size: 9px;
					height: 14px;
					position: absolute;
					margin-top: 20px;
					margin-left: 15px;
				}
			}

			.logout {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 14px;
				color: #FFF;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-decoration: none;
				padding-bottom: 6px;
				&:hover {
					color: darken(#ffffff, 15%);
				}
			}
		}
	}

	&__header-mobile {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
	}

	&__nav-mobile {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		overflow-x: scroll;
		white-space: nowrap;
		padding-left: 15px;
		padding-top: 15px;
		padding-right: 15px;
		gap: 10px;
		height: 85px;

		.nav-item {
			display: flex;
			width: 97px;
			height: 70px;
			flex-shrink: 0;
			border-radius: 2px;
			border: 1px solid #D1D4D8;
		}

		.protected-link {
			display: flex;
			flex-direction: column;
			gap: 7px;
			color: #1A273D;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-decoration: none;
			align-items: center;
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			padding: 8px;

			&-active {
				font-weight: 700;
				background: #F7F7F7;
			}
		}

		.custom-badge {
			border-radius: 20px;
			background-color: #CF0000;
			padding-left: 6px;
			padding-right: 6px;
			font-size: 10px;
			height: 15px;
			position: sticky;
			margin-left: -10px;
			margin-top: 10px;
		}

		.settings {
			display: flex;
			width: 42px;
			height: 42px;
			flex-shrink: 0;
			border: 1px solid #D1D4D8;
			border-radius: 20px;
			justify-content: center;
			align-items: center;

			&-active {
				background: #F7F7F7;
			}
		}


	}

	&__nav-mobile::-webkit-scrollbar {
		/* Hide scrollbar for Webkit browsers */
		display: none;
	}

	&__nav-mobile {
		/* IE and Edge */
		-ms-overflow-style: none;
		/* Firefox */
		scrollbar-width: none;
	}

}
