$screen-md: 769px;
$screen-sm: 414px;

.homepage {
	&__introduction {
		display: flex;
		flex-direction: column;
		text-align: center;

		@media(min-width: $screen-md) {
			text-align: left;
			flex-direction: row;
		}
	}

	&__introduction-image {
		max-height: 100px;

		@media(min-width: $screen-md) {
			margin-right: 50px;
		}
	}

	&__question {
		margin-bottom: 25px;
		text-align: center;

		@media(min-width: $screen-md) {
			text-align: left;
		}
	}

	&__steps {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
	}

	&__step {
		display: flex;
		align-items: center;
		flex-direction: column;
		min-width: 200px;
		margin: 0 25px 25px;
		text-align: center;
		flex: 1;

		img {
			max-height: 74px;
		}

		&__title {
			margin-top: 15px;
      font-weight: 500;
			line-height: 1.3;
      margin-bottom: 3px;
		}
	}

	&__question-answer {
		margin-bottom: 30px;
		text-align: center;
		@media(min-width: $screen-md) {
			text-align: left;
		}
	}

	&__title {
		text-align: center;
		margin-bottom: 15px;
	}

	&__sub-title {
		text-align: center;
		max-width: 600px;
		margin: 0 auto 17px;
	}

	&__button {
		padding: 9px 24px;
		display: block;
		margin-bottom: 7px;

		@media (min-width: $screen-sm) {
			display: inline-block;
			margin-right: 10px;
		}
	}

	&__useful-links {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	&__link {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 300px;
		background: #fff;
		box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
		padding: 20px;
		margin: 10px;
		text-align: center;
		flex: 1 1 0;
	}
}

.link {
	&__title {
		img {
			max-width: 250px;
			max-height: 40px;
			margin-bottom: 15px;
		}
	}

	&__description {
		margin-bottom: 30px;
	}

	&__button {
		margin-top: auto;
	}

	&__button-dark {
		border-radius: 50px;
		padding: 10px 35px;
	}
}

