.header-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	background: #1a273d33;

	.header {
		max-width: 1440px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 16px 19px;
		align-items: center;

		@media (min-width: 1025px) {
			padding: 28px 80px;
		}

		&__logo {
			height: 60px;
		}

		&__login-landing {
			cursor: pointer;
			color: #1A273D;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.52px;
			text-transform: uppercase;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}

		&__login-other {
			color: black;
			cursor: pointer;
			border-radius: 4px;
			background: #FDFDFD;
			color: #1A273D;
			font-family: "Bricolage Grotesque";
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.52px;
			text-transform: uppercase;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}

		&__login-container {
			display: flex;
			align-items: center;
			gap: 5px;	
			padding-left: 10px;
			padding-right: 14px;
			padding-bottom: 3px;
			padding-top: 3px;
		}

		&__icon-landing {
			font-size: 24px;
		}

		&__icon-other {
			font-size: 24px;
			color: #FCA800;
		}
	}

}