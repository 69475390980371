.create-request {
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media (min-width: 1025px) {
		gap: 30px;
	}

	&__form-label {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 5px;

		&__text {
			color: #000;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.firstSection {
		max-width: 500px;
	}

	.secondSection {
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: space-between;

		@media (min-width: 1025px) {
			flex-direction: row;
			gap: 50px;
		}

		&__container {
			display: flex;
			flex-direction: column;
			gap: 30px;
			max-width: 500px;
			width: 100%;
		}

		&__row {
			display: flex;
			flex-direction: column;
			gap: 20px;

			@media (min-width: 1025px) {
				flex-direction: row;
			}
		}

		&__field {
			width: 100%;

			@media (min-width: 1025px) {
				width: 50%;
			}
		}

		&__blueContainer {
			border-radius: 0.375rem;
			background: #E6EDF6;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding-left: 26px;
			height: 111px;

			@media (min-width: 1025px) {
				margin-top: 26px;
				height: calc(100% - 26px);
				margin-top: 26px;
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 12px;
				display: block;
			}
		}
	}

	.thirdSection {
		max-width: 364px;
	}

	.fourthSection {
		max-width: 500px;
	}

	.fifthSection {
		&__buttons {
			display: flex;
			flex-direction: row;
			gap: 15px;
			margin-top: 2px;
		}

		.ring {
			width: 45px;
			height: 45px;
			border: 2px solid #D1D4D8;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #D1D4D8;
			cursor: pointer;
			transition: background-color 0.3s, border-color 0.3s;
		}

		.ring span {
			color: #1A273D;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.ring.selected {
			border-color: #24BD19;
		}
	}

	.is-mobile {
		display: flex;
		flex-direction: column;

		&__title {
			color: #1A273D;
			font-family: "DM Sans";
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 20px;
			margin-top: 30px;
		}

		&__input_label {
			color: #000;
			font-family: "DM Sans";
			font-size: 12px;
			font-weight: 400;
			margin-bottom: 8px;
		}

		@media (min-width: 1025px) {
			display: none;
		}
	}

	.is-desktop {
		display: none;

		@media (min-width: 1025px) {
			display: flex;
		}
	}

	.forms-table {
		border-collapse: collapse;
		width: 100%;
		margin-top: 20px;
	}

	.forms-table th,
	.forms-table td {
		border: 1px solid #D1D4D8;
		padding: 6px 11px;
	}

	.forms-table th {
		color: #000;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.forms-table tbody tr {
		border: none;
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.forms-table tbody tr td {
		border-left: 1px solid #D1D4D8;
		border-right: 1px solid #D1D4D8;
	}

	.forms-table tbody tr:last-child td {
		border-bottom: 1px solid #D1D4D8;
	}

	.input-group-text {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&__accept-terms-link {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-decoration-line: underline;
	}

	&__accept-terms-link:hover {
		cursor: pointer;
	}

	&__submit {
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #F7F7F7;
		padding: 24px 34px;

		@media (min-width: 1025px) {
			align-items: flex-end;
		}
	}

	&__submit_btn {
		border-radius: 30px;
		background: #1A273D;
		border-color: #1A273D;
		color: #FFF;
		text-align: center;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		display: flex;
		width: 165px;
		height: 44px;
		padding: 13px 22px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
	}

	&__submit_btn:hover {
		background: #293e61;
		border-color: #293e61;
	}





	// TODO check what is in use

	label {
		display: flex;
		align-items: center;
	}

	&__description-label {
		flex-direction: column;
		line-height: 1;
		align-items: start !important;
	}

	&__help {
		max-height: 17px;
		margin-left: 9px;
	}

	strong {
		font-weight: 500;
	}

	&__button {
		width: 190px;
		height: 50px;
	}

	&__oil-info {
		background: #f7f7f7;
		padding: 10px;
		border-radius: 3px;
	}

	&__button-container {
		margin-top: 20px;
	}

	&__total {
		line-height: 1;
		padding: 25px 0 10px;
	}

	&__checkbox .custom-control-label {
		position: unset;
		width: 30px;

		&::after,
		&::before {
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 30px;
			width: 30px;
		}
	}

	.custom-control {
		display: inline-block;
	}

	.bean-container {
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		margin: -9px 0 0 -9px;
		width: calc(100% + 9px);

		&.is-invalid .bean {
			border-color: #dc3545;
		}

	}

	.bean {
		cursor: pointer;
		display: flex;
		align-items: center;
		border-radius: 3px;
		line-height: 1.3;
		padding: 7px 20px 7px;
		text-align: left;
		overflow: hidden;
		margin: 9px 0 0 9px;

		&--active {
			background: #3b4bf5;
			color: #fff;
		}

		.bean__dormant {
			height: 38px;
			line-height: 1;
			display: flex;
			align-items: center;
			color: #333;
		}

		&__text {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			line-height: 38px;
			padding-right: 15px;
		}

		input {
			width: 150px;
		}
	}

	h6 {
		padding-bottom: 0.5rem;
		border-bottom: 2px solid #ffc107;
		font-weight: 300;
	}
}

.tooltip-inner {
	strong {
		font-weight: 500;
	}
}


.rdtSwitch,
.dow {
	font-weight: 500;
}
