.ringsSection {
	display: flex;
	flex-direction: row;
	gap: 15px;
	margin-top: 2px;

	.ring {
		width: 45px;
		height: 45px;
		border: 2px solid #D1D4D8;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #D1D4D8;
		cursor: pointer;
		transition: background-color 0.3s, border-color 0.3s;
	}

	.ring span {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.ring.selected {
		border-color: #24BD19;
	}
}

.statusSection {
	display: flex;
	flex-direction: row;
	gap: 15px;
	margin-top: 2px;
	flex-wrap: wrap;

	.box {
		padding: 9px 16px;
		border: 1px solid #B1B7C7;
		border-radius: 6px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #F7F7F7;
		cursor: pointer;
		transition: background-color 0.3s, border-color 0.3s;
	}

	.box span {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.ring {
		border-radius: 50%;
		width: 8px;
		height: 8px;
		margin-right: 8px;
	}

	.box.selected {
		background: #E6EDF6;
	}
}

.filters {
	display: flex;
	flex-direction: column;
	gap: 10px;

	&__container {
		display: flex;
		flex-direction: column;
		background: #F7F7F7;
		padding: 20px;
		gap: 20px;
	}

	&__container * {
		font-family: "DM Sans";
	}

	&__row1 {
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (min-width: 1025px) {
			flex-direction: row;
			justify-content: space-between;
			gap: 20px;
		}

		&__item1 {
			width: 100%;

			@media (min-width: 1025px) {
				width: 40%;
			}
		}

		&__item2 {
			width: 100%;

			@media (min-width: 1025px) {
				width: fit-content;
			}
		}
	}

	&__row2 {
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: space-between;

		@media (min-width: 1025px) {
			flex-direction: row;
			gap: 0px;
		}

		&__item2 {
			width: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: end;

			@media (min-width: 1025px) {
				width: 271px;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
			}
		}
	}

	&__row3 {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (min-width: 1025px) {
			flex-direction: column;
			align-items: flex-end;
		}
	}



	&__label {
		color: #000;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-bottom: 8px;
	}

	&__search_btn {
		display: flex;
		width: 165px;
		height: 44px;
		padding: 13px 22px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
		border-radius: 30px;
		background: #FCA800;
		border-color: #FCA800;
		color: #1A273D;
		text-align: center;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	&__search_btn:hover {
		background: lighten(#FCA800, 10%) !important;
		border-color: lighten(#FCA800, 10%) !important;
		color: #1A273D !important;
	}

}

.totalContainer {
	color: #000;
	font-family: "DM Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 30px;
}


.requestList {
	margin-top: 10px;
	display: grid;
	row-gap: 10px;
}

.requestContainer {
	display: grid;
	column-gap: 0px;
	align-items: center;
	grid-template-columns: auto;
	border-radius: 4px;
	overflow: hidden;
	border: 1px solid #D1D4D8;
	background: #F7F7F7;
	padding: 13px;
	row-gap: 10px;

	@media (min-width: 1025px) {
		grid-template-columns: minmax(0, 2fr) minmax(0, 1.1fr) minmax(0, 0.8fr) minmax(0, 0.7fr) 90px auto;
		row-gap: 0x;
		column-gap: 10px;
	}

	.column {
		font-size: 15px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		text-wrap: wrap;
		font-family: "DM Sans";
		overflow-y: hidden;
		word-wrap: break-word;
		height: 100%;
		word-break: break-word;
		hyphens: auto;

		@media (min-width: 1025px) {
			overflow-x: hidden;
			height: 100%;
		}
	}

	&__label {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	&__value {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&__details_btn {
		border-radius: 30px;
		background: #1A273D;
		color: #FFF;
		text-align: center;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		padding: 9px 21px;
		text-decoration: none;
	}

	&__details_btn:hover {
		background: lighten(#1A273D, 10%);
	}

	.ring {
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		margin-left: 8px;
	}
}



// TODO remove

.request {
	display: grid;
	grid-template-columns: 2fr 2fr 1.4fr;
	grid-column-gap: 15px;
	padding: 13px;
	// background-image: linear-gradient(#fff, rgba(162, 162, 162, 0.04));

	//#19273e42
	border-bottom: 1px solid #eeeeee;

	&__id {
		span {
			// margin-right: 7px;
			font-size: 16px !important;
		}
	}

	&__mobile {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@media (min-width: 769px) {
			display: none;
		}
	}

	.request__oil {
		display: flex;
	}

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0;
		padding-right: 0;

		.request__oil {
			display: block;
		}

		.request__detail {
			grid-column-gap: 10px;
			grid-template-columns: 1fr 1fr;
		}

		.request__detail-item-left {
			text-align: right;
		}

		&__container,
		.request__oil,
		.request__info {
			width: 100%;
		}

		.request__time {
			margin: 3px auto;
		}

		.request__look-button,
		.request__date {
			display: none;
		}

		.request__id,
		.request__oil-title {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		.request__bid {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.oils {
			justify-content: center;
			margin-bottom: 13px;
		}

		.request__mobile {
			justify-content: center;
		}
	}

	&__oil-title {
		line-height: 1;
	}

	&__info {
		position: relative;
	}

	&__date {
		position: absolute;
		line-height: 1.3;
		top: 0;
		right: 0;

		&--mobile {
			line-height: 1;
			text-align: right;
		}
	}

	&__look-button {
		position: absolute;
		bottom: 5px;
		right: 0;
		font-weight: 400;
		// background: #eee;
		// border-color: #eee;
		// color: #212529;

		// &:hover {
		// 	color: #212529;
		// 	background: darken(#eee, 0.9);
		// 	border-color: darken(#eee, 0.9);
		// }

		&--mobile {
			background: #eee;
			border-color: #eee;
			color: #212529;
			font-weight: 400;
		}
	}

	&__time {
		h5 {
			text-align: center;
		}
	}

	&__detail {
		margin-top: 15px;
		display: grid;
		grid-template-columns: 1.5fr 3fr;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
		grid-template-areas: ". ." ". ." ". ." ". ." ". .";
		grid-gap: 0.5rem;
	}

	&__detail-item {
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: 1;
		overflow: hidden;
	}

	&__bid,
	&__state {
		margin-left: 10px;
		color: #fff;
		border-radius: 30px;
		padding: 2px 7px;
		font-weight: 500;
	}

	&__bid {
		margin-left: 5px;
		background: #eee;
		color: #212529;
	}

	&__id {
		white-space: nowrap;
		line-height: 1;
		//color: #007bff;
		text-decoration: none;
	}

	&:hover .request__date {
		//color: #000;
		//transition-duration: 100ms;
	}

	&__info {
		display: flex;
		align-items: center;
	}

	&__table {
		margin-top: 27px;
		//text-overflow: ellipsis;
		//white-space: nowrap;
		//overflow: hidden;

		th {
			font-weight: 400;
			line-height: 1.3;
		}

		th:first-child {
			float: right;
			width: 113px;
		}
	}

	&__date {
		div {
			text-align: right;
			font-weight: 400;
		}
	}

	&__requester {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 0;
		//width: 133px;
	}

	h2 {}

	a {
		color: #212529;
	}
}

.pagination {
	margin-top: 15px;
	display: flex;
	position: relative;
	margin-bottom: 75px;
	height: 38px;

	@media (min-width: 769px) {
		margin-bottom: 35px;
	}

	&__left {
		line-height: 38px;
		white-space: nowrap;
		padding-right: 25px;
	}

	&__right {
		width: 100%;
		max-width: 250px;
		margin-left: auto;
	}

	&__container {
		position: absolute;
		width: 140px;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 60px;

		@media (min-width: 769px) {
			top: 0;
		}

		img {
			height: 12px;
			align-self: center;
		}

		input {
			text-align: center;
			width: 55px;
			margin: 0 5px;
			font-weight: 500;
			display: inline-block;
		}
	}

	&__button {
		font-weight: 500;
		background: transparent;
		border: none;
		color: #495057;
		cursor: pointer;
		overflow: hidden;
		outline: none;
		padding: 0;
		margin: 0 5px;

		&--left {
			margin-right: 12px;
		}
	}

	#pagination__button--left {
		margin-right: 12px;
	}

	#pagination__button--right {
		margin-left: 12px;
	}
}

.nav {
	flex-direction: column;

	.nav-item .active {
		border: 1px solid #dee2e6;
	}

	.nav-link {
		width: 100%;
	}

	@media(min-width: 769px) {
		flex-direction: row;

		.nav-item .active {
			border: 1px solid;
			border-color: #dee2e6 #dee2e6 #fff;
		}

		.nav-link {
			width: unset;
		}
	}
}

.filter {
	border-radius: 5px 0;
	display: grid;
	grid-column-gap: 25px;
	grid-template-columns: 1fr;

	@media (min-width: 769px) {
		grid-template-columns: 2fr 2fr 1fr;
	}

	&__search-container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;

		@media (min-width: 769px) {
			padding: 0;
			background: transparent;
		}
	}

	&__search {
		font-weight: 500;
		position: relative;
		width: 150px;
		height: 50px;
		border-radius: 50px;

		img {
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			height: 23px;
			left: 9px;
		}
	}

	label {
		margin-bottom: 0;
	}
}

.navigation {
	@media (min-width: 768px) {
		display: flex;
	}

	&__view {
		display: flex;
		justify-content: center;
		margin: 0;
		flex-wrap: wrap;
	}

	&__view-item-image {
		height: 80px;
		width: 80px;
		padding: 16px;
		pointer-events: none;
	}

	&__view-button {
		padding: 0;
		height: 80px;
		width: 80px;
		border-radius: 50px;
		background: #efefef;
		margin: 0 auto 11px;
	}

	&__view-item {
		text-align: center;
		display: flex;
		flex-direction: column;
		margin-left: 0;
		margin-right: 10px;
		flex: 1;
		width: 130px;

		&--active {
			.navigation__view-button {
				background: #ffc107 !important;
				box-shadow: 0px 0px 13px 0px rgba(199, 199, 199, 0.8);
			}

			.navigation__view-item-text {
				font-weight: 500;
			}
		}
	}

	&__view-item-text {
		font-weight: 400;
		line-height: 1;
	}

	&__actions {
		display: flex;
		justify-content: flex-end;

		@media (min-width: 768px) {
			flex-direction: column;
			margin-left: auto;
		}
	}

	&__action-button {
		font-weight: 500;
		margin: 3px;
		width: 45px;
		height: 45px;
		line-height: 1.1;
		padding: 11px;
		text-transform: uppercase;
		margin-top: 15px;

		img {
			height: 20px;
		}

		@media (min-width: 768px) {
			margin-top: 3px;
		}
	}
}


.find-request-modal {
	border-radius: 100px;
}

.modal-lg {
	max-width: 960px !important;
}

.modal-backdrop {
	opacity: 0.7 !important;
}

.confirm {
	font-family: Roboto, sans-serif;
	padding-left: 0 !important;
	padding-bottom: 24px !important;
	padding-top: 5px !important;

	@media (min-width: 769px) {
		padding-bottom: 0px !important;
	}

	&__button {
		border-radius: 50px;
		padding-left: 15px;
		margin-right: 10px;
		padding-right: 15px;
	}

	&__cancel {
		border-radius: 50px;
		padding-left: 15px;
		margin-right: 10px;
		padding-right: 15px;

		&:hover {
			color: #000;
		}
	}

	&__emphasize {
		font-weight: 500;
	}
}

.request-page-window-button {
	position: fixed;
	z-index: 50;
	right: 15px;
	top: 15px;
	padding: 0;

	@media (min-width: 1440px) {
		display: none;
	}
}

.request-page-window-image {
	height: 100%;
	max-height: 32px;
	max-width: 32px;
	pointer-events: none;
}