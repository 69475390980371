.select {

  .select__control {
    border-color: #ced4da;

    &--is-focused {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
      -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
      -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    }

    &:hover {
      border-color: #ced4da;
    }
  }

  &.is-invalid {
    .select__control--menu-is-open,
    .select__control {
      border-color: #dc3545;
      box-shadow: none;
    }
    .select__control--is-focused {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}
