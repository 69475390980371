.oil-request-details {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 30px;

	.firstSection {
		display: flex;
		flex-direction: column;
		gap: 10px;

		@media (min-width: 1025px) {
			flex-direction: row;
			gap: 30px;
		}

		&__sub {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		&__parent {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
		}

		&__child {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			width: 100%;

			@media (min-width: 600px) {
				flex: 1 1 48%;
			}
		}
	}

	.backBtn {
		display: flex;
		flex-direction: row;
		gap: 8px;
		text-decoration: none;
		align-items: center;
		width: fit-content;

		&__text {
			color: #B1B7C7;
			font-family: "Inter";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			text-decoration: none;
		}
	}

	.contact {
		display: flex;
		flex-direction: column;
		padding: 23px 20px;
		gap: 16px;
		border-radius: 2px;
		background: #F7F7F7;
		width: 100%;

		&__row {
			display: grid;
			grid-template-columns: auto;
			row-gap: 16px;

			@media (min-width: 1025px) {
				grid-template-columns: 60% 40%;
			}
		}
	}

	.requestDetails {
		display: flex;
		flex-direction: column;
		padding: 23px 20px;
		gap: 16px;
		border-radius: 2px;
		background: #F7F7F7;
		width: 100%;

		&__row {
			display: grid;
			grid-template-columns: auto;
			row-gap: 16px;

			@media (min-width: 1025px) {
				grid-template-columns: 33% 33% 33%;
			}
		}

		.ring {
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-left: 8px;
		}
	}

	.winnerSection {
		display: flex;
		gap: 10px;
		flex-direction: column;

		&__details {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding: 23px 20px;
			gap: 20px;
			border-radius: 2px;

			@media (min-width: 1025px) {
				flex-direction: row;
				gap: 50px;
			}
		}
	}

	.oilDetails {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 23px 20px;
		gap: 16px;
		border-radius: 2px;
		background: #F7F7F7;
		width: 100%;
		height: fit-content;
	}

	&__field {
		display: flex;
		flex-direction: row;
		gap: 10px;

		&__labelContainer {
			width: 100%;
			word-wrap: break-word;
			display: flex;
			flex-direction: column;
			gap: 5px;

			&__label {
				color: #1A273D;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			&__value {
				color: #1A273D;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				word-break: break-word;
			}
		}

	}

	.bids-section {

		&__no-bids {
			color: #000;
			font-family: "DM Sans";
			font-size: 14px;
			font-weight: 600;
			padding: 10px 0px;
		}

		&__lightGrey {
			border-radius: 4px;
			background: #E8E9EB;
		}

		&__table {
			color: #1A273D;
			font-family: "DM Sans";
			font-size: 14px;
			font-weight: 400;
			--bs-table-bg: none;
			margin-bottom: 0px;

			thead,
			tbody,
			tfoot,
			tr,
			td,
			th {
				border-style: none !important;
			}

			tr:nth-of-type(2n+1) {
				border-radius: 4px;
				background: #E8E9EB;
			}

			.winner {
				border-style: solid !important;
				border: 3px solid rgb(57 166 50);
				border-radius: 4px;
			}

			&__btn {
				width: 135.178px;
				height: 44px;
				border-radius: 30px;
				border-color: #1A273D;
				background: #1A273D;
				color: #FFF;
				text-align: center;
				font-family: "DM Sans";
				font-size: 14px;
				font-weight: 600;
				align-self: flex-end;
			}
		}

		&__desktop {
			display: none;

			@media (min-width: 1025px) {
				display: block;
			}
		}

		&__desktop_tr {
			display: none;

			@media (min-width: 1025px) {
				display: table-row;		
			}
		}

		&__mobile {
			display: flex;
			flex-direction: column;
			word-break: break-all;
			gap: 10px;

			.winner {
				border-style: solid !important;
				border: 2px solid rgb(57 166 50);
				border-radius: 4px;
			}

			&__title {
				color: #1A273D;
				font-family: "DM Sans";
				font-size: 14px;
				font-weight: 700;
				margin-bottom: 3px;
			}

			&__value {
				color: #1A273D;
				font-family: "DM Sans";
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 15px;
			}

			@media (min-width: 1025px) {
				display: none;
			}
		}

		&__mobile:nth-of-type(2n+1) {
			border-radius: 4px;
			background: #E8E9EB;
		}

		&__header {
			color: #000;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

	}

	.questions {
		&__container {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		&__no-questions {
			color: #000;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding: 10px 0px;
		}

		&__question {
			display: flex;
			border-radius: 2px;
			background: #F7F7F7;
			padding: 15px;
			color: #1A273D;
			font-family: "DM Sans";
			font-size: 14px;
			font-weight: 700;
			justify-content: space-between;
		}

		&__answer {
			display: flex;
			color: #1A273D;
			font-family: "DM Sans";
			font-size: 14px;
			font-weight: 700;
			justify-content: flex-start;

			@media (min-width: 1025px) {
				justify-content: flex-end;
			}
		}

		&__ask_form {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;

			@media (min-width: 1025px) {
				width: 50%;
			}

			&__labelContainer {
				display: flex;
				flex-direction: row;
				gap: 5px;
				align-items: center;
				margin-top: 20px;
			}

			&__label {
				color: #1A273D;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}

		&__answer_form {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
		}

		&__btn {
			width: 160.119px;
			height: 44px;
			border-radius: 30px;
			border-color: #1A273D;
			background: #1A273D;
			color: #FFF;
			text-align: center;
			font-family: "DM Sans";
			font-size: 14px;
			font-weight: 600;
			align-self: flex-end;
		}
	}

	.createOrEditBid {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;

		&__formFields {
			border-radius: 2px;
			background: #F7F7F7;
			padding: 20px 20px;
			display: flex;
			flex-direction: column;
			gap: 30px;

			&__btn {
				width: 160.119px;
				height: 44px;
				border-radius: 30px;
				border-color: #1A273D;
				background: #1A273D;
				color: #FFF;
				text-align: center;
				font-family: "DM Sans";
				font-size: 14px;
				font-weight: 600;
				align-self: flex-start;
				margin-top: 15px;
			}

			&__btnAdd {
				width: 160.119px;
				height: 44px;
				border-radius: 30px;
				border-color: #1A273D;
				background: #FFF;
				color: #1A273D;
				text-align: center;
				font-family: "DM Sans";
				font-size: 14px;
				font-weight: 600;
				align-self: flex-start;
			}

			&__subTitle {
				color: #1A273D;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 30px;
			}

			@media (min-width: 1025px) {
				padding: 20px 30px;
			}

			&__oilSection {
				margin-top: 10px;
				display: flex;
				flex-direction: column;

				@media (min-width: 1025px) {
					flex-direction: row;
				}

				&__title {
					color: #1A273D;
					font-family: "DM Sans";
					font-size: 14px;
					font-weight: 600;
					width: 10%;
					display: flex;
					align-items: flex-start;
					padding-top: 0px;
					padding-bottom: 10px;

					@media (min-width: 1025px) {
						padding-top: 7px;
						padding-bottom: 0px;
					}
				}

				&__inputContainers {
					display: flex;
					flex-direction: column;
					gap: 10px;
				}

				&__inputContainer {
					display: grid;
					grid-template-columns: 125px auto;
					flex-direction: row;
				}

				&__label {
					display: flex;
					color: #1A273D;
					font-family: "DM Sans";
					font-size: 14px;
					font-weight: 400;
					align-items: center;
					margin-bottom: 0px;
					justify-content: flex-start;
					max-height: 35px;
					width: 110px;

					@media (min-width: 1025px) {
						justify-content: flex-end;
					}
				}

				&__comment_label {
					display: flex;
					color: #1A273D;
					font-family: "DM Sans";
					font-size: 14px;
					font-weight: 400;
					align-items: center;
					justify-content: flex-start;
					max-height: 35px;
					margin-top: 40px;
				}

				&__sumContainer {
					display: flex;
					flex-direction: column;
					margin-top: 26px;
				}

				&__sumRow {
					display: grid;
					grid-template-columns: auto 90px;
					column-gap: 17px;
				}

				&__sumLabel {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					margin-bottom: 0px;
					color: #1A273D;
					text-align: right;
					font-family: "DM Sans";
					font-size: 12px;
					font-weight: 400;
				}

				&__sumValue {
					display: flex;
					align-items: center;
					color: #1A273D;
					font-family: "DM Sans";
					font-size: 12px;
					font-weight: 400;
					padding: 0px;
				}

				&__btn {
					width: 160.119px;
					height: 44px;
					border-radius: 30px;
					border-color: #1A273D;
					background: #1A273D;
					color: #FFF;
					text-align: center;
					font-family: "DM Sans";
					font-size: 14px;
					font-weight: 600;
					align-self: flex-end;
				}
			}
		}


	}

}

.endRequest {
	display: flex;
	justify-content: center;

	@media (min-width: 1025px) {
		justify-content: flex-end;
	}

	&__btn {
		border-radius: 30px;
		background: #DB9E21;
		border-color: #DB9E21;
		display: flex;
		width: 144.889px;
		height: 44px;
		padding: 13px 20px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
		color: #1A273D;
		text-align: center;
		font-family: "DM Sans";
		font-size: 14px;
		font-weight: 600;
	}

	&__btn:hover {
		background: #eeab25;
		border-color: #eeab25;
	}
}




// TODO remove old styles

.oil-request-page {
	padding: 0;


	&__help {
		max-height: 17px;
		margin-left: 9px;
	}

	.modal-content {
		border-radius: 10px;
	}

	&__request-winner {
		line-height: 1;
		margin-top: 15px;
		text-align: center;
	}

	&__find-request {
		display: grid;
		grid-template-columns: 3fr 1fr;
		align-items: center;
		justify-items: end;
		grid-gap: 10px;
	}

	&__find-title {
		font-weight: 500;
		line-height: 1;
	}

	&__find-search {
		font-weight: 500;
		position: relative;
		width: 120px;
		height: 45px;
		border-radius: 50px;

		img {
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			height: 20px;
			left: 9px;
		}
	}

	@media (min-width: 769px) {
		padding: 10px 15px 15px;
	}

	&__heading {
		display: flex;
		align-items: center;
	}

	&__introduction {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
	}

	&__close {
		position: absolute;
		top: -2px;
		right: 0;
	}

	&__introduction-request {
		margin-right: 7px;
	}

	&__state {
		margin-left: auto;
		float: right;
		color: #fff;
		border-radius: 30px;
		padding: 8px 15px;
		font-weight: 500;
		background: #0D47A1;

		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&__introduction-title {
		display: flex;
		align-items: center;
		flex: 1;
		line-height: 1;
		overflow: hidden;
	}

	&__info-right {
		padding: 1rem;
		background: #f5f5f5;
		border-radius: 13px;

		&__title {
			padding: 1rem;
			padding-top: 0;
			text-align: center;
		}
	}

	&__mobile-hidden {
		display: none;

		@media(min-width: 769px) {
			display: table;
		}
	}

	&__mobile-visible {
		display: block;

		@media(min-width: 769px) {
			display: none;
		}
	}

	&__mobile-show {
		position: absolute;
		right: 0;
		bottom: 0;
		display: inline-block;
		margin-left: auto;
		transform: translateY(50%);

		@media(min-width: 769px) {
			display: none;
		}
	}

	&__bids {
		display: grid;
		// grid-template-columns: 1fr;
		column-gap: 0.5rem;
		text-align: left;

		div {
			padding: 0;
		}

		@media(min-width: 769px) {
			// grid-template-columns: repeat(9, 1fr);
			grid-auto-rows: auto;
			text-align: left;

			div {
				padding: 10px;
			}
		}
	}

	&__bid-description-mobile {
		display: block;
		font-weight: 500;
		position: relative;
		padding: 1rem;

		@media(min-width: 769px) {
			display: none;
		}
	}

	&__bid-comment {
		padding: 0 !important;
	}

	&__bids-title {
		border-bottom: 1px solid #f5f5f5;
		// background: #fff !important;
		line-height: 1.3;
		// font-weight: 500;
		display: none;

		@media(min-width: 769px) {
			display: block;
		}
	}

	&__info-right-container {
		display: grid;
		grid-template-columns: 1fr;
		text-align: left;
		line-height: 1.2;
	}

	&__block {
		border-radius: 5px;
		padding: 15px 25px;
		padding-left: 0;
		padding-top: 5px;
		margin-bottom: 25px;
	}

	&__time-left {
		margin-bottom: 5px;
		font-weight: 400;
	}

	&__info-box {
		border-radius: 7px;

		strong {
			font-weight: 500;
		}
	}

	&__total {
		line-height: 38px;
		font-weight: 500;
	}

	&__comment {
		// font-weight: 500;
		display: block;

		@media (min-width: 769px) {
			display: inline;
		}
	}

	&__winner {
		position: relative;
		width: 65px;
	}

	&__choose-winner-button {
		background: transparent;
		height: 35px;
		width: 35px;
		border: none;
		outline: 0;
		padding: 0;
		float: right;

		img {
			height: 100%;
			width: 100%;
			cursor: pointer;
			opacity: 0.7;
		}

		&:hover img {
			opacity: 1;
		}
	}

	&__confidential {
		height: 35px;
		margin-bottom: 4px;
	}

	&__action-button-container {
		display: flex;
		align-items: center;
	}

	&__back-button {}

	&__action-button {
		margin-left: auto;
		line-height: 30px;
		border-radius: 3px;
		width: 150px;
	}

	&__bid-create {
		font-weight: 400;
		border-radius: 7px;
		margin-bottom: 2rem;

		.input-group-text {
			background: #fff;
		}

		label {
			font-weight: 500;
		}

		.form-group label {
			display: block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	&__question-creation {
		font-weight: 400;
		margin-bottom: 7px;

		label {
			margin: 0;
		}
	}

	&__info {
		display: grid;
		grid-template-columns: 1fr;
		height: fit-content;
		margin-bottom: 7px;

		@media (min-width: 769px) {
			grid-template-columns: 175px 1fr;
			grid-template-columns: repeat(4, 1fr);
			gap: 0.5rem;
		}
	}

	&__title {
		font-weight: 500;
		text-align: left;

		@media (min-width: 769px) {
			font-weight: 400;
			margin-top: 0;
			text-align: right;
		}
	}

	&__oils {
		.oils {
			justify-content: center;
		}
	}

	&__title-orange {
		padding-bottom: 0.5rem;
		border-bottom: 2px solid #ffc107;
		font-weight: 300;
	}

}

.winner-bid {
	td {
		background: #f8f9fa;
	}
}


.question {
	line-height: 1.2;

	&__heading {
		display: flex;

		&__question {
			flex: 1;
			margin-right: 20px;
		}
	}

	&__bold {
		margin-bottom: 0;
	}

	p {
		line-height: 1.3;
		overflow-wrap: break-word;
	}

	&__sent {
		font-weight: 400;
		float: right;
	}

	&__answer {
		background: #f5f5f5;
		position: relative;
		padding: 13px;
		margin-top: 13px;
		border-radius: 7px;
		margin-bottom: 13px;
		word-break: break-word;

		&:after,
		&:before {
			bottom: 100%;
			left: 35px;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:before {
			border-color: rgba(194, 225, 245, 0);
			border-bottom-color: #f5f5f5;
			border-width: 10px;
			margin-left: -26px;
		}
	}
}

.create-or-edit-bid {
	.accordion-button:not(.collapsed) {
		background: #fff;
	}

	.accordion-header {
		button {
			color: unset;
		}
	}
}