.landing-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.slogan {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 40px;
		max-width: 658px;
		padding-top: 50px;

		/* Desktop view */
		@media (min-width: 1025px) {
			padding-top: 130px;
		}

		&__title {
			color: #1A273D;
			font-family: "Bricolage Grotesque";
			font-size: 40px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			max-width: 300px;

			@media (min-width: 1025px) {
				font-size: 64px;
				max-width: 100%;
			}
		}

		&__content {
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			font-family: "DM Sans";
			margin-bottom: 10px;
			margin-right: 0px;

			@media (min-width: 1025px) {
				margin-right: 20px;
			}
		}

		&__button {
			background: #DB9E21;
			color: #212529;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
			letter-spacing: 0.48px;
			padding: 16px 24px;
			border-radius: 6px;
			text-decoration: none;
		}

		&__button:hover {
			background-color: darken(#DB9E21, 10%);
		}
	}

	&__img {
		display: none;

		/* Desktop view */
		@media (min-width: 1025px) {
			display: inline;
		}
	}
}

.benefits {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	padding-top: 48px;
	padding-bottom: 65px;
	gap: 20px;

	/* Desktop view */
	@media (min-width: 1025px) {
		flex-direction: row;
		padding-top: 140px;
		padding-bottom: 102px;
		gap: 40px;
	}

	.benefit {
		display: flex;
		flex-direction: row;
		padding: 30px;
		gap: 26px;
		width: 100%;

		&__text {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		&__title {
			color: #1A273D;
			font-family: "Bricolage Grotesque";
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 1;
			word-break: break-word;
		}

		&__content {
			color: #1A273D;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 26px;
		}
	}
}

.counters {
	display: flex;
	justify-content: center;

	&__content {
		display: flex;
		width: 100%;
		max-width: 1440px;
		justify-content: space-around;
		flex-direction: column;
		padding: 0px;

		@media (min-width: 1025px) {
			flex-direction: row;
			padding: 0px 80px;
		}
	}

	&__counter {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;
		padding: 45px 0px;
	}

	&__title {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&__value {
		color: #1A273D;
		text-align: center;
		font-family: "Bricolage Grotesque";
		font-size: 48px;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
	}

	&__divider {
		display: block;
		width: 100vw;
		margin-left: -20px;

		@media (min-width: 1025px) {
			display: none;
		}
	}
}

.tutorial {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 20px;


	@media (min-width: 1025px) {
		padding-top: 100px;
		padding-bottom: 50px;
	}

	&__title {
		color: #1A273D;
		font-family: "Bricolage Grotesque";
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.diagram-desktop {
		padding-top: 100px;
		text-align: center;
		width: 956px;
		display: none;

		@media (min-width: 1025px) {
			display: block;
		}

		.diagram-container {
			counter-reset: list-counter;
			border-top: 1px solid #DB9E21;
			display: flex;
			list-style: none;
			padding: 0;
			justify-content: space-between;
			align-items: stretch;
			align-content: stretch;
		}

		.dot {
			counter-increment: list-counter;
			position: relative;
			margin-top: 40px;
			width: 100%;
		}

		.dot:first-child {
			margin-left: -119px;
		}

		.dot:last-child {
			margin-right: -119px;
		}

		.dot::after {
			content: counter(list-counter);
			width: 64px;
			height: 64px;
			position: absolute;
			border-radius: 32px;
			background: #DB9E21;
			top: -73px;
			left: 50%;
			transform: translatex(-50%);
			color: #000;
			font-family: "Bricolage Grotesque";
			font-size: 24px;
			font-style: normal;
			font-weight: 800;
			line-height: normal;
			padding-top: 17px;
		}
	}

	.diagram-mobile {
		padding-top: 50px;
		text-align: center;
		width: 300px;
		display: block;

		@media (min-width: 1025px) {
			padding-top: 100px;
			display: none;
		}

		.diagram-container {
			counter-reset: list-counter;
			border-left: 1px solid #DB9E21;
			display: flex;
			list-style: none;
			padding: 0;
			justify-content: space-between;
			align-items: stretch;
			align-content: stretch;
			flex-direction: column;
			height: 450px;
			margin-left: 20px;
		}

		.dot {
			counter-increment: list-counter;
			position: relative;
			margin-top: 0px;
			width: 100%;
			padding-left: 50px;
			display: flex;
			justify-content: start;
		}

		.dot::after {
			content: counter(list-counter);
			width: 64px;
			height: 64px;
			position: absolute;
			border-radius: 32px;
			background: #DB9E21;
			top: 0px;
			left: 0%;
			transform: translatex(-50%);
			color: #000;
			font-family: "Bricolage Grotesque";
			font-size: 24px;
			font-style: normal;
			font-weight: 800;
			line-height: normal;
			padding-top: 17px;
		}
	}


	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__text-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-bottom: 50px;
		padding-top: 0px;
		@media (min-width: 1025px) {
			padding-top: 10px;
		}
	}

	&__text-title {
		color: #1A273D;
		font-family: "Bricolage Grotesque";
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		align-self: flex-start;

		@media (min-width: 1025px) {
			align-self: center;
		}
	}

	&__text-content {
		color: #1A273D;
		text-align: left;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding-top: 10px;		

		@media (min-width: 1025px) {
			text-align: center;
		}
	}




}