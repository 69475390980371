.loader {
  text-align: center;
  margin: 15px 0 50px;
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(169, 169, 169, 0.3);
  border-radius: 50%;
  border-top-color: #969696;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}