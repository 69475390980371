.page-title {
	color: #1A273D;
	font-family: "DM Sans";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 20px;

	@media (min-width: 1025px) {
		margin-bottom: 25px;
		font-size: 32px;
	}
}