.oilList {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 4px;

	&__oil {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
	}

	&__amount {
		color: #000;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&__description {
		color: #000;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&__ring {
		width: 37px;
		height: 37px;
		border: 2px solid #D1D4D8;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #D1D4D8;
		transition: background-color 0.3s, border-color 0.3s;
	}

	&__ring span {
		color: #1A273D;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;

	}

	&__item {
		display: flex;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.1;
		flex-direction: row;
		flex-wrap: wrap;
		white-space: nowrap;
		margin-right: 7px;
		margin-bottom: 4px;
		border-radius: 25px;
		background: #eeeeee;
		padding: 4px 20px 4px 7px;
		align-content: center;
		justify-content: center;
	}

	&__item-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 11px;
		line-height: 1;
	}

	&__item-title {
		align-self: center;
		align-content: center;
		background: #fff;
		line-height: 40px;
		text-align: center;
		width: 40px;
		border-radius: 50%;
	}
}