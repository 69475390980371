.eye-show {
	#eye {
		display: none !important;
		visibility: hidden;
	}
}

.cross-button:hover {
	fill: #a61d19;
}


.settings {
  &__img {
    height: 18px;
  }

  &__footer {
    margin-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 15px;
  }

	.loader {
		text-align: left;
	}

	&__error {
		margin-bottom: 1rem;
	}

	&__add_button {
		border-radius: 50px;
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 18px;
		}
	}

	&__content {
		padding: 10px 10px 30px;

		@media (min-width: 769px) {
			padding: 0 30px 50px;
		}

	}

  hr {
    margin-bottom: 7px;
  }

  &__password {
    .input__container .form-control.is-invalid {
      background-image: none;
    }
  }

	&__profile-error {
		margin-bottom: 20px;
	}

	&__profile-grid {
		display: grid;
		grid-template-columns: 1fr;
		margin-bottom: 1rem;

		@media (min-width: 769px) {
			grid-template-columns: repeat(4, 1fr);
			gap: 0.5rem;
		}
	}

	&__profile-title {
		display: flex;
		align-items: center;

		@media (max-width: 768px) {
			line-height: 1;
			font-weight: 500;
			margin-top: 5px;
		}
	}

	&__profile-image {
		height: 18px;
		margin-left: 7px;
	}

	&__profile-input {
		margin-top: 3px;
		display: flex;
		align-items: center;
	}

	&__save {
		width: 130px;
		height: 40px;
		font-weight: 500;
		border-radius: 50px;
	}

	.image-circle {
		pointer-events: none;
		padding: 9px;
		background: #fff;
		border-radius: 50%;

		img {
			height: 35px;
		}
	}

	h5 {
		font-weight: 500;
		margin-bottom: 4px;
		//margin: 0 0 0 15px;
	}

	.profile {
		display: flex;
		margin: 35px 0 75px 100px;

		.profile-picture-container {
			.profile-picture {
				height: 100px;
				width: 100px;
				background: #f8f8f8;
				border-radius: 50%;
			}

			.change-picture {
				text-align: center;
			}
		}
	}

	.company {
		margin: 35px 100px 75px 100px;

		label {
		}

		.form-control {
			max-width: 150px;
			border-radius: 2px;
			outline: none;
			box-shadow: none;
			height: 40px;
		}

		.fa-plus-circle {
			color: #f07e39;
			margin-bottom: 13px;
		}

		.add-company {
			margin-top: 35px;

			.btn {
				height: 40px;
				font-weight: 500;
				padding: 0 25px;
			}

			.cancel {
				background: #fafafa;
				color: #333333;
				border-color: #d7d7d7;
				border-radius: 2px;
			}

			.cancel:hover {
				background: #f2f2f2;
			}

			.add-company-btn {
				background: #f07e39;
				border-color: #c36d2c;
				margin-right: 13px;
				color: #ffffff;
				font-weight: 500;
				border-radius: 2px;
			}

			.add-company-btn:hover {
				background: #e77638;
			}
		}

		.company-content {
			width: 100%;

			.alert-success {
				margin-top: 25px;
			}

			.company-pic {
				height: 75px;
				width: 75px;
				border-radius: 75px;

				img {
					height: 75px;
					width: 75px;
					border-radius: 75px;
				}
			}

			table {
				width: 100%;

				.picture-container {
					display: flex;
					width: 75px;
					align-items: center;
					flex-direction: column;
					position: relative;
				}

				thead th {
					color: #8e8e8e;
					font-weight: 400;
				}

				tbody tr th {
					font-weight: 400;
					color: #3c3c3c;
					padding: 4px 0;
				}

				tbody th:last-child i {
					color: #ababab;
				}

				tbody th:last-child i:hover {
					color: #ca3c2e;
				}

				.change {
					position: absolute;
					background: rgba(27, 27, 27, 0.8);;
					border-radius: 75px;
					width: 100%;
					height: 100%;
					color: #f0f0f0;
					text-transform: none;
					display: none;
					vertical-align: middle;
					font-weight: 700;
					letter-spacing: 0.3px;
				}

				.active {
					display: table;
					cursor: pointer;
				}

				.center {
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					opacity: 1;
				}
			}
		}
	}

	.fa {
		padding: 4px;
	}

	.fa:hover {
		cursor: pointer;
		opacity: 1.1;
		transform: scale(1.2);
		transition: all 0.2s;
	}

	.collapse-button {
		display: flex;
		align-items: center;
		border: 0;
		height: 80px;
		width: 100%;
		background: #f8f9fa;
    margin-bottom: 13px;
		text-align: left;
		outline: none;
		box-shadow: none !important;
	}
}

.add-image-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 360px;
	justify-content: center;

	.btn-dark {
		height: 40px;
		padding: 0 23px;
		font-weight: 500;
		margin-top: 7px;
		background: #fafafa;
		color: #333333;
		border-color: #d7d7d7;
		pointer-events: none;
	}

	.drop-zone {
		background: #fff;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 0 auto;
		border-radius: 0;
		height: 100%;
		font-weight: 500;
		color: #8c8c8c;

		img {
			height: 96px;
		}

		span {
			margin-top: 7px;
			font-weight: 400;
			letter-spacing: 0.5px;
			color: #a2a2a2;
			//text-transform: uppercase;
			pointer-events: none;
		}

		span:nth-child(3) {
		}
	}

	.no-right {
		border-right: none;
	}

	canvas {
		border-radius: 3px;
	}
}

.picture-modal-header {
	.picture-modal-header-title {
		color: #444444;
	}

	.picture-modal-header-navigation {
		margin-top: 8px;
		font-weight: 500;
		color: #474747;
	}

	#separator {
		margin: 0 7px;
		color: #474747;
	}

	.choose {
		font-weight: 400;
		cursor: pointer;
		padding: 5px 5px 5px 0;
		visibility: hidden;
	}

	.choose-active {
		visibility: visible;
		font-weight: 400;
		cursor: pointer;
		padding: 5px 5px 5px 0;
	}

	#image {
		font-weight: 500;
	}
}

.icon-buttons {
	margin-top: 5px;
	float: right;

	div {
		display: flex;
		align-items: flex-end;
		text-transform: uppercase;
		font-weight: 500;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		/* Non-prefixed version, currently
										 supported by Chrome and Opera */
		color: #7b7b7b;
	}

	div:hover img {
		transform: scale(1.2);
		transition-duration: 0.3s;
		cursor: pointer;
	}

	div:hover {
		cursor: pointer;
	}

	img {
		margin-right: 9px;
		margin-top: 9px;
		margin-left: 13px;
		height: 23px;
	}
}


.modal-lg {
	max-width: 900px;
}

.modal-content {
	border-radius: .1rem;
}

.drop-zone {
	cursor: pointer;
}

.btn:focus, .btn:active {
	outline: none !important;
	box-shadow: none;
}

.save-not-active {
	opacity: 0.3;
	pointer-events: none;
}

.collapse-header {
	margin-left: 15px;
	pointer-events: none;
	line-height: 1;
}

.modal-button {
  width: 130px;
  height: 40px;
  font-weight: 500;
  border-radius: 50px;
}
