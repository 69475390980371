.customer-stories {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: "DM Sans";
    word-break: break-word;

    .all {
        display: flex;
        margin: 20px 0px;

        @media (min-width: 1025px) {
            margin: 40px 0px;
        }

        &__row {
            display: flex;
            flex-direction: column;
            gap: 40px;

            @media (min-width: 1025px) {
                display: flex;
                flex-direction: row;
                gap: 40px;
            }

            &__item {
                display: flex;
                flex-direction: column;
                gap: 15px;

                &__title {
                    color: #1A273D;
                    font-family: "Bricolage Grotesque";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    @media (min-width: 1025px) {
                        color: #1A273D;
                        font-family: "DM Sans";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                &__title:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .mobileImg {
        margin-top: 20px;
        margin-bottom: 10px;

        @media (min-width: 1025px) {
            margin-top: 40px;
            margin-bottom: 10px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
    }

    .title {
        color: #1A273D;
        font-family: "Bricolage Grotesque";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 20px;

        @media (min-width: 1025px) {
            color: #1A273D;
            font-family: "DM Sans";
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .subTitle {
        color: #1A273D;
        font-family: "DM Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media (min-width: 1025px) {
            color: #1A273D;
            font-family: "DM Sans";
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .quote {
        margin-left: 35px;

        @media (min-width: 1025px) {
            margin-left: 80px;
        }
    }
}