.page-section-title {
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding-left: 10px;
    height: 32px;
    background: #E6EDF6;
    color: #1A273D;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}